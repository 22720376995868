<template>
  <div class="console-box">
    <div class="console-container">
      <div :class="{'right-class': $i18n.locale === 'ar'}" class="console-title">
        {{ $t('Teller.Console') }}
        <img src="../assets/icons/controlls-icon.svg" :style="{'float': $i18n.locale !== 'ar' ? 'right': 'left'}"
             class="controls-button"/>
      </div>
      <div class="screen-container">
        <div class="console-buttons-container">
          <lbrx-button id="callNext" v-model="disable" style="margin-bottom: -5px" icon="next-button-icon"
                       @clicked="callNext()" :base-button="true"
                       :label="$t('Teller.Next')" color="#FFFFFF" background="#00CC6A" height="8.5vmin" width="100%"
                       font-size="1.3"></lbrx-button>
          <lbrx-button id="callAbsent" v-model="waiting_disable" style="margin-bottom: -5px" icon="delete-button-icon"
                       @clicked="callAbsent()" :base-button="true"
                       :label="$t('Teller.Absent')" color="#FFFFFF" background="#FF5757" height="8.5vmin" width="100%"
                       font-size="1.3"></lbrx-button>
        </div>

        <div class="vert-buttons">
          <lbrx-button v-if="teller_config.business.myLiberrex.queue_on_hold === 1" id="callClose"
                       style="margin-right: 1vmin" v-model="waiting_disable" icon="remove-button-icon" :small="true"
                       @clicked="callClose()" :label="$t('Teller.Close')" color="#FFFFFF" background="#444444"
                       height="9vmin"
                       width="100%" font-size="1.9"></lbrx-button>

          <lbrx-button v-if="teller_config.business.myLiberrex.queue_on_hold !== 1" id="callClose" v-model="waiting_disable"
                       style="margin-bottom: -5px" icon="delete-button-icon"
                       @clicked="callClose()" :label="$t('Teller.Close')"
                       :base-button="true"
                       color="#FFFFFF" background="#444444" height="8.5vmin" width="100%" font-size="1.3"></lbrx-button>

          <lbrx-button v-if="teller_config.business.myLiberrex.queue_on_hold === 1" id="callWait"
                       v-model="waiting_disable"
                       icon="hourglass-button-icon" :small="true" @clicked="callWait()"
                       :label="$t('Teller.Waiting')" color="#FFFFFF" background="#FFCE00" height="9vmin" width="100%"
                       font-size="1.9"></lbrx-button>
        </div>
      </div>

      <div class="mobile-container sticky">
        <div class="mobile-container">
          <lbrx-button id="callClose" v-model="waiting_disable" icon="remove-button-icon" :small="true" @clicked="callClose()"
                       color="#FFFFFF"
                       background="#444444" height="13vmin" width="100%" font-size="1.9"></lbrx-button>
          <lbrx-button v-if="teller_config.business.myLiberrex.queue_on_hold === 1" id="callWait" v-model="waiting_disable"
                       icon="hourglass-button-icon" :small="true" @clicked="callWait()" color="#FFFFFF"
                       background="#FFCE00" height="13vmin" width="100%" font-size="1.9"></lbrx-button>

          <lbrx-button id="callAbsent" v-model="waiting_disable" icon="delete-button-icon" :small="true" @clicked="callAbsent()"
                       color="#FFFFFF"
                       background="#FF5757" height="13vmin" width="100%" font-size="1.9"></lbrx-button>
          <lbrx-button id="callNext" v-model="disable" icon="next-button-icon" :small="true" @clicked="callNext()"
                       color="#FFFFFF"
                       background="#00CC6A" height="13vmin" width="100%" font-size="1.9"></lbrx-button>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import LbrxButton from "@/components/LbrxButton.vue";
import {queueService} from "@/_services";

export default {
  name: 'LbrxTellerConsole',
  emits: ['sending'],
  props: {
    modelValue: Boolean,
    ticket: Object
  },
  components: {LbrxButton},
  data() {
    return {
      sending: false,
      teller_config: this.teller_config = JSON.parse(localStorage.getItem("user") || "{}"),
      ticketStatus: false
    }
  },
  watch: {
    ticket: {
      handler: function (newVal, oldVal) {
        if (newVal !== null) {
          this.ticketStatus = newVal.status !== "inservice"
        }
      },
      deep: true
    }
  },
  computed: {
    disable() {
      return this.modelValue || this.ticket === null
    },
    waiting_disable() {
      return this.modelValue || this.ticketStatus || this.ticket === null
    }
  },
  mounted() {
    this.sending = this.modelValue
  },
  methods: {
    callNext() {
      this.sending = true;
      let counter_config = JSON.parse(localStorage.getItem("counter") || "{}");
      this.$emit('resetstat');
      this.$emit('sending');
      queueService.callNext(counter_config.queue_id).then((res) => {
        this.$emit('callNext', res);
      }).catch((err) => {
        console.log(err)
        this.$emit('callNext', null);
      })

      setTimeout(() => {
        this.sending = false;
      }, 1000);
    },
    callAbsent() {
      this.sending = true;
      this.$emit('sending');
      this.$emit('ticketAbsent');
      setTimeout(() => {
        this.sending = false;
      }, 1000);
    },
    callClose() {
      this.sending = true;
      this.$emit('endTicket')
      setTimeout(() => {
        this.sending = false;
      }, 1000);
    },
    callWait() {
      this.sending = true;
      this.$emit('sending');
      this.$emit('holdTicket');
      setTimeout(() => {
        this.sending = false;
      }, 1000);
    }
  }
}
</script>
<style scoped>

@media (max-width: 680px) {
  @media (max-width: 767px) {
    .sticky {
      z-index: 1;
      position: fixed !important;
      bottom: 7vh !important;
      left: 0 !important;
      right: 0 !important;
      font-size: 20px !important;
    }
  }
  .console-box {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }

  .screen-container {
    display: none !important;
  }

  .mobile-container {
    display: flex !important;
    width: 100% !important;
    padding: 0 !important;
  }

  .console-title {
    display: none !important;
  }
}

.controls-button {
  height: 3.5vmin;
  margin-top: 0.3vmin;
  margin-bottom: -1vmin;
  cursor: pointer;
}

.mobile-container {
  display: none;
}

.vert-buttons {

  display: flex;
}

.console-buttons-container {
  display: flex;
  margin-top: -0.5vmin;
  flex-direction: column;
  width: 100%;
}

.console-title {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 2.4vmin;
  line-height: 2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.console-container {
  margin: auto;
}

.console-box {
  padding: 20px;
  width: 100% !important;
  height: 100% !important;
  background: white;
}
</style>