import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"

import {createApp} from 'vue'
import 'mdb-vue-ui-kit/css/mdb.min.css'
import App from './App.vue'
import router from './router'
import {ApiConfigs, authHeader} from './_helpers';
import Vue3TouchEvents from "vue3-touch-events";

// Vuex Store
import store from "./store";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)

//Bootstrap
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap-icons/font/bootstrap-icons';
// import 'bootstrap-icons/font/bootstrap-icons.css'

// import i18n from './i18n/i18n.js'
if (!localStorage.getItem('Language')) {
    localStorage.setItem('Language', 'en')
}

import Echo from 'laravel-echo';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Pusher from 'pusher-js';
import {createI18n} from "vue-i18n";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import fr from "./locales/fr.json";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import en from "./locales/en.json";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ar from "./locales/ar.json";
import {businessService} from "@/_services";

window.Pusher = Pusher;
/*
const echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    forceTLS: false,
    wsHost: process.env.VUE_APP_WSS_URL,
    wsPort: 6001,
    disableStats: false,
    enabledTransports: ['ws', 'wss'],
    authEndpoint: `${ApiConfigs.broadcast_url}${ApiConfigs.pusher.auth}`,
    auth: {
        headers: {...authHeader()}
    },
});

if (localStorage.getItem('user')) {

    echo.channel("private-teller-" + JSON.parse(localStorage.getItem('user') || '{}').user.id)
        .listen(
            ".maintenance-change",
            () => {
                businessService
                    .refresh()
                    .then(function () {
                        location.reload()
                    })
                    .catch(
                        function (err) {
                            console.log(err)
                        })
            })
}

 */


const i18n = createI18n({
    locale: localStorage.getItem('Language') || 'fr',
    fallbackLocale: "fr",
    messages: {fr, en, ar},
});

//import { VueReCaptcha } from 'vue-recaptcha-v3'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
//app.use(VueReCaptcha, { siteKey: `6LcaHMAUAAAAAJbeqKGhe_w2P_u3uw7srIj4SAKM` })

//app.provide('echo', echo)

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

app.component('VueDatePicker', VueDatePicker);
app.provide('sitekey',process.env.VUE_RECAPCHA_SECRET)
app.provide('direction', localStorage.getItem('Language') === 'ar' ? 'rtl' : 'ltr')
app.provide('text_direction', localStorage.getItem('Language') === 'ar' ? 'right' : 'left')
app.use(store).use(router).use(Vue3TouchEvents).use(i18n).mount('#app')
