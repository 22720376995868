<template>
  <div v-if="modelValue" class="popup-container">

    <Transition>
      <div v-if="showPopup" class="popup-box">
        <div class="container d-flex flex-column h-100">

          <div class="row flex-grow-0">
            <div class="col-11">
              <div class="popup-title just">
                <img class="popup-title-icon"
                     :style="{'margin-left': $i18n.locale === 'ar' ? '4vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '4vmin'}"
                     src="../assets/icons/calendar-grey-icon.svg" alt=""/>
                <div class="popup-title-text">
                  {{ $t('BookingDetails.title') }}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{ $t('Teller.Booking') }} : {{ booking.fname }} {{ booking.lname }} -
                {{ moment(booking.start_datetime).locale(this.$i18n.locale).format('D/M/Y') }}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>

          <div class="row">
            <div v-if="!loading" class="popup-body" :class="booking.notes.length === 0?'h-100':''">
              <div class="note-date booking-header-date">
                {{
                  moment(booking.start_datetime).locale(this.$i18n.locale).format('dddd Do MMMM, H:mm').toString().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                }} <a class="put-ticket-btn" @click="triggerPushTicket()">({{ $t('BookingDetails.create_ticket') }})</a>
              </div>

              <div class="user-name">
                {{ booking.fname }} {{ booking.lname }}
              </div>
              <div class="services">
                <span class="title">{{ $t('BookingDetails.Services') }}: </span>
                <span :id="'bookingService-'+service.id" v-for="(service,index) in booking.services_array" :key="index">{{
                    service.title
                  }}<span
                      v-if="index+1 < booking.services_array.length">, </span></span>
              </div>
              <div class="services hori-container">
                <div>
                  <span class="title">{{ $t('BookingDetails.Department') }}: </span>
                  <span id="bookingDep">{{ booking.department_id != null ? dep.name : 'No related Dep' }}</span>
                </div>
                <div class="margin-left">
                  <span class="title">{{ $t('BookingDetails.agent') }}: </span>
                  <span id="bookingMember" v-if="booking.member">{{
                      member.fname + ' ' + member.lname
                    }}</span>
                  <span v-else>
                    {{ $t('BookingDetails.vide') }}
                  </span>
                </div>
              </div>

              <div @click="showUserData = !showUserData" class="title-container">
                <img v-if="showUserData" class="arrow-icon" src="../assets/icons/down-arrow.svg"/>
                <img v-else class="arrow-icon" src="../assets/icons/left-arrow.svg"/>
                {{ $t('Teller.CustomerLabel') }}
              </div>
              <div v-if="showUserData" class="user-data">
                <div class="first-data justify-content-between">
                  <span><span class="title">{{ $t('BookingDetails.lname') }}: </span><span
                      class="value">{{ booking.lname }} </span></span>
                  <span><span class="title">{{ $t('BookingDetails.fname') }}: </span><span
                      class="value">{{ booking.fname }}</span></span>
                  <span><span class="title">{{ $t('BookingDetails.phone') }}: </span><span
                      class="value">{{ booking.phone_number }}</span></span>
                </div>
                <span class="first-data"><span class="title">{{ $t('BookingDetails.mail') }}: </span><span
                    style="margin-left: 5px"
                    class="value">{{
                    booking.email
                  }}</span></span>
              </div>
              <div @click="showNotes = !showNotes" class="title-container">
                <img v-if="showNotes" class="arrow-icon" src="../assets/icons/down-arrow.svg"/>
                <img v-else class="arrow-icon" src="../assets/icons/left-arrow.svg"/>
                {{ $t('BookingDetails.notes') }}
              </div>
              <div id="loader" v-if="commentLoader"
                   :style="{'background':commentLoader?'rgba(255,255,255,0.87)':'unset'}"
                   style="position: relative;width: 100%;height: 100%;z-index: 10001522283">
                <div id="secondLoader" v-if="commentLoader" class="spinner-border" :style="{'color': '#008fca'}"
                     style="margin: auto;height: 9vmin;width: 9vmin;font-size: 3vmin;position: absolute; top: 50%; right: 50%;"
                     role="status">
                </div>
              </div>
              <div v-if="showNotes" class="notes">
                <div v-if="booking.notes > 1" class="vertical-line"></div>
                <div>
                  <div v-for="(note, index) in booking.notes" :key="index" class="single-note justify-content-between">
                    <div class="marker">

                    </div>
                    <div style="width: 28vmin; padding:2px">
                      <div class="note-date">{{ getStringDate(note.created_at) }}</div>
                      <div class="note-agent">{{ note.member.lname + ' ' + note.member.fname }}</div>
                    </div>
                    <div class="note-data">
                      {{ note.note }}
                    </div>
                  </div>
                </div>
              </div>
              <textarea v-if="showNotes" type="text" class="note-input" :class="{'right-class': $i18n.locale === 'ar'}"
                        :placeholder="$t('BookingDetails.addNote')+'...'" v-model="notes"></textarea>
            </div>
            <div class="popup-buttons">
              <lbrx-button id="confirmAction" v-model="sending" icon="menu-button-icon-white" :base-button="true"
                           :class="{'right-class': $i18n.locale === 'ar'}"
                           @clicked="triggerComment()"
                           :label="$t('BookingDetails.create_note')"
                           color="#FFFFFF" background="#00CC6A" height="9vmin" width="65%"
                           font-size="1.1" align="left" style="margin-left: 3vmin;"></lbrx-button>
              <lbrx-button id="cancelAction" v-model="close" icon="delete-red-icon" :base-button="true"
                           :class="{'right-class': $i18n.locale === 'ar'}"
                           @clicked="closeThis()" :label="$t('Teller.Close')"
                           color="#FF5757" border="1px solid #FF5757" background="white" height="9vmin"
                           width="65%" font-size="1.1" align="right" style="margin-right: 3vmin;"></lbrx-button>
            </div>
          </div>

        </div>

      </div>
    </Transition>
  </div>
  <lbrx-toast v-if="showToastPopup" v-model="showToastPopup" :type="toastType" :message="toastData"
              @close="showToastPopup = false"></lbrx-toast>
</template>

<script>

import moment from "moment/moment";
import {businessService, queueService} from "@/_services";
import LbrxButton from "@/components/LbrxButton.vue";
import LbrxToast from "@/components/LbrxToast.vue";

export default {
  name: 'LbrxBookingDetailsPopup',
  components: {LbrxToast, LbrxButton},
  props: {
    modelValue: Boolean,
    booking: {type: Object, required: true},
    date: {type: String, required: true},
    agencyInfo: {type: Object, required: true}
  },
  data() {
    return {
      showNotes: true,
      showUserData: true,
      sending: false,
      showPopup: false,
      loading: true,
      dt: new Date(this.date),
      dep: {},
      member: {},
      comments: [],
      toastType: 'danger',
      toastData: {
        title: '',
        details: ''
      },
      showToastPopup: false,
      notes: "",
      commentLoader: true
    }
  },
  computed: {
    moment() {
      return moment
    },
    verticalLineHeight() {
      if (this.booking.notes) {
        if (this.booking.notes.length < 3)
          return 4.2 * this.booking.notes.length + "vmin";
        if (this.booking.notes.length === 3)
          return 5.8 * this.booking.notes.length + "vmin";
        return 6.5 * this.booking.notes.length + "vmin";
      }
      return 6.5 + "vmin";
    },
    getDate() {
      return this.getStringDate(this.dt);

    },
    getFullDate() {
      return moment(this.dt).locale(this.$i18n.locale).format('dddd Do MMMM YYYY, H:mm')
    }
  },
  methods: {
    openToast(type, data) {

      this.toastData = data
      this.toastType = type

      console.log('show')
      this.showToastPopup = true

    },
    triggerPushTicket() {

      let services = null
      let business_services = null
      let queue = null

      services = this.booking.services_array.map(a => a.parent_service_id).join(',')
      business_services = this.booking.services_array.map(a => a.service_id).join(',')

      if (services !== null && business_services !== null) {
        queueService.getServicesQueues(business_services).then((res) => {
          if (res.queues.length > 0) {
            queue = res.queues.at(0)
          }
        }).then(() => {
          let payload = {
            customer_id: this.booking.customer_id,
            services: services,
            origin: "Teller"
          }
          console.log(payload, queue.id)
          if (queue !== null) {
            this.sending = true
            businessService.pushTicket(payload, queue.id).then((res) => {
              console.log(res)
              if (res.status === "success") {
                this.openToast('success', {
                  title: 'Toast.Success.title',
                  details: 'Toast.Success.sub-title'
                })
                this.closeThis()
              }
            }).catch((err) => {
              console.log(err)
              this.openToast('danger', {
                title: 'Toast.Danger.title',
                details: 'Toast.Danger.sub-title'
              })
              this.sending = false
            })
          } else {
            this.openToast('danger', {
              title: 'Toast.Danger.title',
              details: 'Toast.Danger.sub-title'
            })
          }

        })
      } else {
        this.openToast('danger', {
          title: 'Toast.Danger.title',
          details: 'Toast.Danger.sub-title'
        })
      }


      /**/
    },
    getStringDate(date) {
      date = new Date(date);
      const options = {day: '2-digit', month: '2-digit', year: 'numeric'};
      return date.toLocaleDateString('en-GB', options);
    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close');
      }, 700)
    },
    triggerComment() {
      if (this.notes !== "") {
        let payload = {
          customer_id: this.booking.customer_id,
          notes: this.notes
        }
        console.log(payload)
        this.sending = true
        businessService.putBookingComment(payload, this.booking.id).then((res) => {
          console.log(res)
          if (res.status === "success") {
            this.openToast('success', {
              title: 'Toast.Success.title',
              details: 'Toast.Success.sub-title'
            })
            this.getComments()
            this.notes = ""
            this.sending = false
          }
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
          this.sending = false
        })
      }

    },
    getComments() {
      this.commentLoader = true
      // eslint-disable-next-line vue/no-mutating-props
      this.booking.notes = []
      businessService.getCustomerComments(this.booking.customer_id).then((res) => {
        this.comments = res.comments.data
        // eslint-disable-next-line vue/no-mutating-props
        this.booking.notes = this.comments
        this.commentLoader = false
      })
    }
  },
  mounted() {
    businessService.getDepartmentById(this.booking.department_id).then((res) => {
      console.log(res)
      this.dep = res.department
      res.department.members.forEach((member) => {
        if (member.id === this.booking.member_id) {
          this.member = member
        }
      })
    })

    this.getComments()

  },
  created() {
    this.dt = new Date(this.date);
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });

    businessService.getDepartmentById(this.booking.department_id).then((res) => {
      console.log(res)
      this.dep = res.department
    })

    this.loading = false;
    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  },
}
</script>

<style scoped>
.popup-box {
  width: 65.6%;
}

.full-date {
  margin-top: 2vmin;

  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 3vmin;
  line-height: 3.7vmin;

  /* Primary */

  color: #008FCA;
}

.user-name {
  margin-top: 2vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vmin;
  line-height: 2vmin;

  /* Dark Gray */

  color: #444444;
}

.first-data .title {
  font-weight: 700;
}

.services .title {
  font-weight: 700;
}

.services {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vmin !important;
  line-height: 2vmin !important;
  margin-top: 2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.hori-container {
  display: flex;

  font-size: 2.5vmin;
  line-height: 2.6vmin;
}

.title-container {
  background: #F2F2F2;
  width: 100%;
  height: 5.5vmin;
  margin-top: 1vmin;
  padding: 1vmin;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3vmin;
  line-height: 3vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.title-container:hover {
  opacity: 0.7;
}

.arrow-icon {
  margin-right: 4vmin;
  height: 3vmin;
  padding-left: 17px;
  padding-top: 3px;
}

.first-data {
  display: flex;
  width: 90%;
  margin-left: 3vmin;
  margin-top: 2vmin;
}

.first-data .value {
  color: #8A8A8A;
}

.note-date {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vmin;
  line-height: 2vmin;
  /* identical to box height */


  /* Primary */

  color: #008FCA;
}

.note-agent {
  margin-top: 0.2vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.8vmin;
  line-height: 2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}

.note-data {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.1vmin;
  line-height: 2vmin;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5vmin;

  /* Gray 3 */

  color: #828282;
  background-color: #f8f8f8;
  margin-right: 2vmin;
}

.single-note {
  display: flex;
  min-height: 6vmin;
  max-height: 8vmin;
  margin-top: 2vmin;
  overflow: hidden;
}

.notes {
  height: auto;
  display: flex;
  margin-top: 1vmin;
  margin-left: 3vmin;
  max-height: 17vb;
  overflow-y: scroll;
}

.marker {
  margin-top: 1vmin;
  margin-right: 2vmin;
  background: #008FCA;
  border-radius: 300px;
  height: 1.8vmin;
  width: 2.6vmin;
}

.vertical-line {
  margin-top: 3vmin;
  height: v-bind(verticalLineHeight);
  background: #F2F2F2;
  border-radius: 5px;
  margin-left: 2vmin;
  margin-right: -1.225vmin;
  width: 0.8vmin;
}

.note-input::placeholder {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vmin;
  line-height: 2.5vmin;

  /* Gray 5 */

  color: #E0E0E0;
}

.note-input {
  margin-bottom: 1vmin;
  text-align: left;
  resize: none;
  padding: 20px;
  margin-top: 2vmin;
  min-height: 10vmin;
  margin-left: 3vmin;
  width: 95%;
  background: #FFFFFF;
  /* #E3E3E3 */
  border: 1px solid #E3E3E3;

  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vmin;
  line-height: 2vmin;

  /* Gray 5 */

  color: black;
}

.margin-left {
  margin-left: 10vmin;
}

.booking-header-date {
  margin-top: 3vmin;
  font-size: 3vmin;
  font-weight: 700;
}

.user-data {
  font-size: 2.3vmin;
}

.popup-body {
  margin-bottom: 1vmin;
}

.put-ticket-btn {
  font-size: 2vmin !important;
  color: #8A8A8A;
  cursor: pointer;
  text-decoration: underline;
}

.button-container {
  display: unset;
}
</style>