<template>
  <div v-if="modelValue" class="popup-container">

    <Transition>
      <div v-if="showPopup" class="popup-box" :class="{'right-class': $i18n.locale === 'ar'}">
        <div class="container d-flex flex-column h-100">

          <div class="row flex-grow-0">

            <div class="col-11">
              <div class="popup-title">
                <img class="popup-title-icon"
                     :style="{'margin-left': $i18n.locale === 'ar' ? '2vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '2vmin'}"
                     v-if="type === 'displays'" src="../assets/icons/affichage-icon.svg"
                     alt=""/>
                <img class="popup-title-icon"
                     :style="{'margin-left': $i18n.locale === 'ar' ? '2vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '2vmin'}"
                     v-else src="../assets/icons/billing-machine-icon.svg" alt=""/>
                <div class="popup-title-text">
                  <span v-if="type === 'displays'"> {{ $t('Teller.displays') }} </span>
                  <span v-else>{{ $t('Teller.kiosks') }}</span>

                </div>
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>
          <div class="list-paginate-container">
            <div class="row">
              <div v-if="!loading" class="popup-body">
                <div class="table-headers container">
                  <div class="row">
                    <div class="col-3">
                      <div class="signle-header">{{ $t('Teller.name') }}</div>
                    </div>
                    <div class="col-4">
                      <div class="signle-header">{{ $t('Teller.last_ping') }}</div>
                    </div>
                    <div class="col-3">
                      <div class="signle-header">{{ $t('Teller.Status') }}</div>
                    </div>
                    <div class="col-2">
                      <div class="signle-header"></div>
                    </div>
                  </div>
                </div>
                <table class="table table-striped" v-if="devices.length > 0">
                  <tbody>
                  <tr v-for="(device, index) in devices" :id="'device-'+index" :key="index">
                    <td class="row-item col-3" valign="middle">{{ device.name }}</td>
                    <td class="row-item col-4" valign="middle">{{
                        device.last_ping_date_time !== null ? moment(device.last_ping_date_time).locale(this.$i18n.locale).format('dddd Do MMMM YYYY, H:mm') : '--'
                      }}
                    </td>
                    <td class="row-item col-3" valign="middle">
                      <div style="display: flex" :class="{'online-text': device.last_ping_status,'offline-text': !device.last_ping_status}">
                         <div >
                          <div class="status animate-flicker mx-2"
                               :class="{'online': device.last_ping_status}"
                               :style="{'margin-top': 'calc(0.7*'+0.3+'*(1vh + 1vw))', height: 'calc(0.5*'+0.7+'*(1vh + 1vw))', width: 'calc(0.5*'+0.7+'*(1vh + 1vw))'}">

                          </div>

                      </div>
                        {{device.last_ping_status ? $t('internet_status.on') : $t('internet_status.off') }}
                      </div>
                    </td>
                    <td class="row-item col-2" valign="middle">
                      <lbrx-sub-menu-button :id="'refrechDevice-'+index" icon="021-refresh" color="white" padding="4"
                                            background="#008FCA" @click="refresh(device.id)"
                                            height="5vmin" width="10vmin" font-size="0.6"></lbrx-sub-menu-button>

                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="no-data-container" v-else>
                  <div class="statistics-image-container">
                    <img src="../assets/images/no-ticket-data.png"/>
                  </div>
                  <div class="no-data-label">{{ $t('Teller.No_stat') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
  <lbrx-toast v-if="showToastPopup" v-model="showToastPopup" :type="toastType" :message="toastData"
              @close="showToastPopup = false"></lbrx-toast>
</template>

<script>

import LbrxQueueListItem from "@/components/LbrxQueueListItem.vue";
import {businessService, queueService} from "@/_services";
import moment from "moment/moment";
import LbrxToast from "@/components/LbrxToast.vue";
import LbrxSubMenuButton from "@/components/LbrxSubMenuButton.vue";

export default {
  name: 'LbrxDevicesPopup',
  components: {LbrxSubMenuButton, LbrxToast},
  inject: ['direction', 'text_direction'],
  props: {
    modelValue: Boolean,
    type: String,
    items: Array
  },
  data() {
    return {
      sending: false,
      showPopup: false,
      loading: true,
      devices: [],
      toastType: 'danger',
      toastData: {
        title: '',
        details: ''
      },
      showToastPopup: false
    }
  },
  mounted() {
    this.devices = this.items
    console.log(this.items)
  },
  methods: {
    openToast(type, data) {

      this.toastData = data
      this.toastType = type

      console.log('show')
      this.showToastPopup = true

    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close');
      }, 700)
    },
    refresh(id) {
      if (this.type === 'displays') {
        businessService.refreshDisplay(id).then((res) => {
          this.openToast('success', {
            title: 'Toast.Success.title',
            details: 'Toast.Success.Refresh'
          })
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        })
      } else {
        businessService.refreshKiosk(id).then((res) => {
          this.openToast('success', {
            title: 'Toast.Success.title',
            details: 'Toast.Success.Refresh'
          })
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        })
      }
    }
  },
  created() {
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });

    this.loading = false;
    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  },
  computed: {
    FixBoxWidth() {
      return '45%'
    },
    moment() {
      return moment
    },
  }
}
</script>

<style scoped>
.input-container {
  margin-left: 0.5vmin;
  margin-right: 0.5vmin;
  width: 45%;
}

.input-container-archive {
  margin-left: 0.5vmin;
  margin-right: 0.5vmin;
  width: 65%;
}

.list-paginate-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.input-label {
  margin-left: 0.5vmin;

  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.6vmin;
  line-height: 2.6vmin;
  /* identical to box height */


  /* Medium Gray */

  color: #8A8A8A;
}

.date-office-container {
  display: flex;
  margin-top: 5vmin;
  width: 100%;
}

.signle-header {
  height: 5vmin;
  margin-bottom: 1vmin;
  padding-bottom: 0.8vmin;
  border-bottom: 1px solid #E3E3E3;
}

.table-headers {
  margin-top: 7vmin;
  width: 100%;
  font-family: 'Exo 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.7vmin;
  line-height: 22px;
  /* identical to box height */


  /* Medium Gray */

  color: #8A8A8A;
}

.popup-box {
  width: v-bind(FixBoxWidth);
}

.statistics-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5vmin;
}

.no-data-label {
  width: 100%;
  text-align: center;
  margin-top: 1vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.4vmin;
  line-height: 4vmin;

  /* Gray Light */

  color: #E3E3E3;
}

.no-data-container {
  width: 100%;
  height: 60%;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
  color: red !important;
}
.offline-text{
  color: red !important;
}
.online-text {
  color: #93F383 !important;
}
</style>