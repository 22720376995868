import {authHeader, ApiConfigs} from "../_helpers";

export const userService = {
    login,
    loginNoRecaptcha,
    logout,
    register,
    getAll,
    checkEmail,
    getById,
    update,
    updatePassword,
    requestPasswordReset,
    passwordReset,
    generateCaptcha
};

function generateCaptcha() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(`${ApiConfigs.accounts.captcha}`, requestOptions).then(
        handleResponse
    );
}

function login(email: string, password: string, captcha: string, key: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({email, password, captcha, key}),
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.login}`,
        requestOptions
    ).then(handleResponse)
        .then((user) => {
            // login successful if there's a jwt token in the response
            if (user) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem("user", JSON.stringify(user));
                //   localStorage.setItem("token", user.token);
            }
            return user;
        });
}

function loginNoRecaptcha(email: string, password: string, recaptcha: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({email, password, recaptcha})
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.login_no_recaptcha}`,
        requestOptions
    )
        .then(handleResponse)
        .then((user) => {
            // login successful if there's a jwt token in the response
            if (user) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem("user", JSON.stringify(user));
                //  localStorage.setItem("token", user.token);
            }
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
    localStorage.removeItem("counter");
    // localStorage.removeItem("token");
    location.reload();
}

function register(user: BodyInit) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: "POST",
        headers: {},
        body: user,
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.register}`,
        requestOptions
    )
        .then(handleResponse)
        .then((user) => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem("user", JSON.stringify(user));
            }
            return user;
        });
}

function getAll() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: "GET",
        headers: authHeader(),
    };

    return fetch(`${ApiConfigs.base_url}/users`, requestOptions).then(
        handleResponse
    );
}

function checkEmail(email: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.check_email.replace(":email", email)}`,
        requestOptions
    ).then(handleResponse);
}

function getById(id: number) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: "GET",
        headers: authHeader(),
    };

    return fetch(`${ApiConfigs.base_url}/users/${id}`, requestOptions).then(
        handleResponse
    );
}

function update(user: BodyInit) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: "POST",
        headers: {...authHeader()},
        body: user,
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.update}`,
        requestOptions
    ).then(handleResponse);
}

function updatePassword(password: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: "POST",
        headers: {...authHeader(), "Content-Type": "application/json"},
        body: JSON.stringify(password),
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.update_password}`,
        requestOptions
    ).then(handleResponse);
}

function requestPasswordReset(password: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: "POST",
        headers: {...authHeader(), "Content-Type": "application/json"},
        body: JSON.stringify(password),
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.reset_password}`,
        requestOptions
    ).then(handleResponse);
}

function passwordReset(password: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: "POST",
        headers: {...authHeader(), "Content-Type": "application/json"},
        body: JSON.stringify(password),
    };

    return fetch(
        `${ApiConfigs.base_url + ApiConfigs.accounts.reset_update_password}`,
        requestOptions
    ).then(handleResponse);
}


function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
            }
            const error = response.status || (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
