<template>
    <router-view ></router-view>
</template>

<style>

@import './assets/css/main.scss';
@font-face {
    font-family: "Inter";
    src: local("Inter"),
    url(./assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf) format("truetype");
}
@font-face {
    font-family: "Digital Numbers";
    src: local("Digital Numbers"),
    url(./assets/fonts/Digital_numbers/DigitalNumbers-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "Exo 2";
    src: local("Exo 2"),
    url(./assets/fonts/Exo_2/Exo2-VariableFont_wght.ttf) format("truetype");
}
@font-face {
    font-family: "Square Peg";
    src: local("Square Peg"),
    url(./assets/fonts/Square_peg/SquarePeg-Regular.ttf) format("truetype");
}
#app {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
button:focus {
    outline: none !important;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
