<template>
  <div v-if="modelValue" class="popup-container">

    <Transition>
      <div v-if="showPopup" class="popup-box" :class="{'right-class': $i18n.locale === 'ar'}">
        <div class="container">
          <div class="row flex-grow-0">
            <div class="col-11">
              <div class="popup-title">
                <img class="popup-title-icon" :style="{'margin-left': $i18n.locale === 'ar' ? '4vmin': '', 'margin-right': $i18n.locale === 'ar' ? '0': '4vmin'}" src="../assets/icons/add-client.svg" alt=""/>
                <div class="popup-title-text">
                  {{ $t('CustomerPopup.title') }}
                </div>
              </div>
              <div class="popup-title popup-sub-title">
                {{ $t('CustomerPopup.Sub-title') }}
              </div>
            </div>
            <div class="col-1">
              <div @click="closeThis()" class="close-popup">
                X
              </div>
            </div>
          </div>
        </div>
        <div class="container" style="overflow: scroll;overflow-x: hidden;">
          <div class="col">
            <form id="client-form" @submit="checkForm">
              <div class="row my-4">

                <div class="col-12 col-md-6">
                 <span class="input-title">
                {{ $t('CustomerPopup.Name') }}
            </span>
                  <input id="fname" :class="{'right-class': $i18n.locale === 'ar'}"
                         type="text"
                         v-model="fname"
                         class="note-input">
                </div>

                <div class="col-12 col-md-6">
                 <span class="input-title">
                {{ $t('CustomerPopup.Lname') }}
            </span>
                  <input id="lname" :class="{'right-class': $i18n.locale === 'ar'}"
                         type="text"
                         v-model="lname"
                         class="note-input">
                </div>
              </div>
              <div class="row">

                <div class="col-12 col-md-6">
                 <span class="input-title">
                {{ $t('CustomerPopup.Email') }}
            </span>
                  <input id="email" :class="{'right-class': $i18n.locale === 'ar'}"
                         type="email"
                         v-model="email"
                         class="note-input">
                </div>

                <div class="col-12 col-md-6">
                 <span class="input-title">
                {{ $t('CustomerPopup.Phone_1') }}
            </span>
                  <vue-tel-input id="telInput" :class="{'right-class': $i18n.locale === 'ar'}"
                                 class="note-input"
                                 v-model="phone_number"
                                 :inputOptions="options"
                                 mode="international"
                                 :onlyCountries="['TN','SA','LY','FR','CI','MA','US','DZ']"
                                 :preferredCountries="['TN','SA']"></vue-tel-input>
                </div>
              </div>
              <div class="row my-4">

                <div class="col-12 col-md-6">
                 <span class="input-title">
                {{ $t('CustomerPopup.Phone_2') }}
            </span>
                  <vue-tel-input id="secondTelInput" :class="{'right-class': $i18n.locale === 'ar'}"
                                 class="note-input"
                                 :inputOptions="options"
                                 v-model="phone_number_2"
                                 mode="international"
                                 :onlyCountries="['TN','SA','LY','FR','CI','MA','US','DZ']"
                                 :preferredCountries="['TN','SA']"></vue-tel-input>
                </div>
              </div>
              <div class="row">

                <div class="col-12 col-md-6">
                 <span class="input-title">
                {{ $t('CustomerPopup.Birthday') }}
            </span>
                  <input id="bdInput" :class="{'right-class': $i18n.locale === 'ar'}"
                         type="date"
                         v-model="dateofbirth"
                         class="note-input" style="padding: 17px">
                </div>

                <div class="col-12 col-md-6">
                 <span class="input-title">
               {{ $t('CustomerPopup.Country') }}
            </span>
                  <multiselect id="countrySelect" :class="{'right-class': $i18n.locale === 'ar'}"
                      v-model="country"
                      :min-chars="1"
                      :delay="0.5"
                      :searchable="true"
                      :options="async function(query) { return await filterCountries(query)}">
                  </multiselect>

                </div>
              </div>
              <div class="row my-4">

                <div class="col-12 col-md-6">
                 <span class="input-title">
                {{ $t('CustomerPopup.Lang') }}
            </span>

                  <select id="langSelect" v-model="preferred_language" class="note-input"
                          :class="{'right-class': $i18n.locale === 'ar'}">
                    <option value="fr" selected :class="{'right-class': $i18n.locale === 'ar'}">
                      Français
                    </option>
                    <option value="en" :class="{'right-class': $i18n.locale === 'ar'}">
                      English
                    </option>
                    <option value="ar" :class="{'right-class': $i18n.locale === 'ar'}">
                      العربية
                    </option>
                  </select>
                </div>

                <div class="col-12 col-md-6">
                 <span  class="input-title">
               {{ $t('CustomerPopup.Id-intern') }}
            </span>
                  <input id="interId" :class="{'right-class': $i18n.locale === 'ar'}"
                         type="text"
                         v-model="internal_id"
                         class="note-input">
                </div>
              </div>
              <div class="row">

                <div class="col-12">
                 <span class="input-title">
                {{ $t('CustomerPopup.Address') }}
            </span>
                  <input id="address" :class="{'right-class': $i18n.locale === 'ar'}"
                         type="text"
                         class="note-input">
                </div>
              </div>
              <div class="row my-4">

                <div class="col-12">
                 <span class="input-title">
                {{ $t('CustomerPopup.Notes') }}
            </span>
                  <textarea id="notesInput" :class="{'right-class': $i18n.locale === 'ar'}" rows="5" v-model="notes"
                            class="note-input"></textarea>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <lbrx-checkbox id="canBookMoreThanOnce" v-model="config" @update:model-value="this.config === $event"
                                 :label="$t('CustomerPopup.Config')"></lbrx-checkbox>
                </div>
              </div>
            </form>

          </div>
        </div>

        <div class="popup-buttons p-1">
          <lbrx-button id="validateCreate" v-model="sending" icon="tick-mark-icon" :base-button="true" @click="triggerPopupAction()"
                       :label="$t('Teller.ConfirmButton')"
                       color="#FFFFFF" background="#00CC6A" height="8vmin" width="93%"
                       font-size="0.9"></lbrx-button>
          <lbrx-button id="cancelCreate" v-model="close" icon="delete-red-icon" :base-button="true" @clicked="closeThis()" :label="$t('Teller.Close')"
                       color="#FF5757" border="1px solid #FF5757" background="white" height="8vmin"
                       width="93%" font-size="0.9"></lbrx-button>
        </div>
      </div>
    </Transition>
    <lbrx-toast v-if="showToastPopup" v-model="showToastPopup" :type="toastType" :message="toastData"
                @close="showToastPopup = false"></lbrx-toast>
  </div>
</template>

<script>
import LbrxButton from "@/components/LbrxButton.vue";
import {businessService} from "@/_services";
import LbrxCheckbox from "@/components/LbrxCheckbox.vue";
import {VueTelInput} from 'vue-tel-input';
import Multiselect from '@vueform/multiselect'
import 'vue-tel-input/vue-tel-input.css';
import LbrxToast from "@/components/LbrxToast.vue";

export default {
  name: 'LbrxAddCustomerPopup',
  components: {LbrxToast, VueTelInput, Multiselect, LbrxCheckbox, LbrxButton},
  props: {
    modelValue: Boolean,
    current_client: Object,
    type: String
  },
  data() {
    return {
      sending: false,
      close: false,
      showPopup: false,
      errors: [],
      countries: require("../_helpers/countries-selector.js"),
      country: "TN",
      email: "",
      fname: "",
      internal_id: null,
      lname: "",
      phone_number: "",
      phone_number_2: "",
      dateofbirth: "",
      preferred_language: "fr",
      notes: "",
      config: false,
      options: { placeholder: "" },
      toastType: 'danger',
      toastData: {
        title: '',
        details: ''
      },
      showToastPopup: false

    }
  },
  watch: {
    config: {
      handler: function (val) {
        console.log(val)
      }
    }
  },
  methods: {

    openToast(type, data) {

      this.toastData = data
      this.toastType = type

      console.log('show')
      this.showToastPopup = true

    },

    checkForm() {
      this.errors = [];

      if (!this.fname.length) {
        this.errors.push("fname required.");
      }

      if (!this.lname.length) {
        this.errors.push("lname required.");
      }

      if (!this.phone_number.length) {
        this.errors.push("fname required.");
      }

      if (!this.errors.length) {
        return true;
      }
      this.sending = false;
      return false
    },
    triggerPopupAction() {
      this.sending = true;
      if (this.checkForm()) {
        let payload = {
          nationality: this.country,
          email: this.email,
          fname: this.fname,
          internal_id: this.internal_id,
          lname: this.lname,
          phone_number: this.phone_number.replaceAll(" ", ""),
          phone_number_2: this.phone_number_2,
          dateofbirth: this.dateofbirth.length,
          preferred_language: this.preferred_language,
          notes: this.notes,
          config: '{"multiple_bookings_on_a_single_day":' + this.config + '}'
        }

        businessService.createCustomer(payload).then((res) => {
          if (res.status === "success") {
            this.openToast('success', {
              title: 'Toast.Success.title',
              details: 'Toast.Success.sub-title'
            })
            this.$emit('confirm-client', res.customer)
          }
        }).catch((err) => {
          console.log(err)
          this.openToast('danger', {
            title: 'Toast.Danger.title',
            details: 'Toast.Danger.sub-title'
          })
        })
      }
    },
    closeThis() {
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('close')
      }, 700)
    },
    filterCountries(query) {
      if (query) {
        return this.countries.filter((country) => {
          return country.label.toLowerCase().indexOf(query) !== -1
        })
      }
      return this.countries
    }
  },
  created() {
    document.addEventListener('keyup', (event) => {
      event.key === 'Escape' ? this.closeThis() : null;
    });
    setTimeout(() => {
      this.showPopup = true;
    }, 5)
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
:root {
  .multiselect-tag {
    background: #008fca !important;
  }

  .multiselect {
    min-height: 2vh!important;
  }

  .multiselect-caret {
    background: black;
    -webkit-mask-image: url('./../assets/images/arrow.png');
  }

  .multiselect-inifite-spinner, .multiselect-spinner {
    background: #008fca !important;
  }

  .multiselect-search , .vti__input {
    background: #FDFDFD;
  }
}

</style>
<style scoped>

.popup-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.note-input::placeholder {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2vmin;
  color: #E0E0E0;
}

.note-input {
  text-align: left;
  padding: 1px!important;
  width: 100%;
  background: #FDFDFD;
  border: 1px solid #E3E3E3;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vmin;
  line-height: 2vmin;
  height: 6vmin !important;
  color: black;
}

.input-title {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-size: 2.2vmin;
  line-height: 2vmin;

  /* Medium Gray */

  color: #8A8A8A;
}
</style>