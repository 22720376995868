<template>
  <div class="button-container">
    <div class="label-style" :class="{'right-class': $i18n.locale === 'ar'}">{{ label }}</div>
    <br>
    <div :class="{'right-class': $i18n.locale === 'ar'}">
      <div style="display: flex;height: 7.1vh;position: absolute;width: 30px">
        <img class="icon-style" :src="getIcon()"/>
      </div>
      <input class="input-style" :type="type" :value="modelValue"
             :style="{'border': has_error?'solid 1px red':'1px solid #E4E4E4'}"
             @input="$emit('update:modelValue', $event.target.value)"/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LbrxInput',
  props: {
    label: {
      required: true,
      value: String
    },
    icon: {
      required: true,
      value: String
    },
    type: {
      required: true,
      value: String
    },
      has_error: {
        required: false,
        value: Boolean
    },
    modelValue: String,
  },
  emits: ['update:modelValue'],
  methods: {
    getIcon() {
      var images = require.context('../assets/')
      return images('./icons/' + this.icon + ".svg")
    },
  }
}
</script>

<style scoped>

@media (max-width: 822px) {

    .input-style {
        font-size: 17px !important;
    }
}
.button-container {
  width: 100% !important;
  text-align: left;
}

.label-style {
  margin-bottom: -1vb;
  width: 100%;
  text-align: left;
  font-family: 'Exo 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.3vmin;
  line-height: 2vmin;
  /* Medium Gray */

  color: #8A8A8A;
}

.input-style {
  background: #FCFCFC;
  border: 1px solid #E4E4E4;
  height: 5vh;
  width: 100%;
  margin-bottom: 1.5vh;
  padding-left: 9vmin;
  padding-right: 9vmin;
  font-size: 2.2vmin;
}

.icon-style {
  height: 2.5vmin;
  margin: 1.3vh 1.3vh;
}

input:focus{
  outline: none;
}
</style>